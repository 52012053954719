import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomText,
  CustomTextInput,
  FallBack,
  Image,
  ModalComponent,
  PrimaryCta,
} from "../../components";
import CustomTable from "../../components/customTable";
import Header from "../header";
import { Loader } from "../loader";
import SideMenu from "../sideMenu";
import Utils from "../../common/utils";
import Core from "../../common/clientSdk";
import { toast } from "react-toastify";
import * as CampaignData from "../../common/data/campaign";
import InfluencerDetailModal from "../../modal/influencerDetialModal";
import Icon from "../../components/core_component/icon";
import { useSelector } from "react-redux";
import ExpandedComponent from "./expandedComponent";

const PriceRequestList = () => {
  const [priceRequestList, setPriceRequestList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [influencerId, setInfluencerId] = useState(null);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [openSendPriceModal, setOpenSendPriceModal] = useState(false);
  const [price, setPrice] = useState();
  const [campaignId, setCampaignId] = useState(null);
  const [sendPriceLoading, setSendPriceLoading] = useState(false);

  useEffect(() => {
    priceList();
  }, []);

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  function priceListUpdateProgress(status) {
    setLoading(status);
  }
  function priceList() {
    let params = {};
    Core.priceList(
      priceListSuccess,
      priceListFailed,
      priceListUpdateProgress,
      params
    );
  }
  function priceListSuccess(res, response) {
    console.log("Invite List:", response.invite_list);

    if (
      Array.isArray(response.invite_list) &&
      response.invite_list.length > 0
    ) {
      setPriceRequestList(response.invite_list);
    } else {
      console.warn("Invite list is empty or not an array");
      setPriceRequestList([]);
    }

    setLoading(false);
  }

  function priceListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function getInflencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]?.n;
    } else {
      return "-";
    }
  }
  const toggleFlyoutDropdown = () => {
    setInfluencerFlyOut(!influencerFlyOut);
  };
  function CampaignClick(campaignId) {
    window.open(`/campaigns/${campaignId}`, "_blank");
  }

  const tableSchema = [
    {
      name: "Influencers",
      selector: (row) => row["influencer_data"]["name"],
      width: "300px",

      cell: (data) => (
        <>
          <span
            onClick={() => {
              toggleFlyoutDropdown();
              setInfluencerId(data.influencer_data.influencer_id);
            }}
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            <Image
              source={
                data.influencer_data.profile_picture
                  ? Utils.generateInfluencerDpUrl(
                      data.influencer_data.influencer_id,
                      data.influencer_data.profile_picture
                    )
                  : require("../../resources/images/user-copy.png")
              }
              className={
                data.influencer_data.is_verified
                  ? "discovery-user-profile"
                  : "proposal-user-profile"
              }
            />
            <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
              <div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    gap: 5,
                    //alignItems: "baseline",
                  }}
                >
                  <CustomText
                    p
                    text={data.influencer_data.name}
                    className="Body-1"
                  />
                </div>
                <span style={{ display: "flex" }}>
                  <span>
                    <CustomText
                      p
                      text={
                        getInflencerLocation(data?.influencer_data)
                          ? getInflencerLocation(data?.influencer_data)
                          : "-"
                      }
                      fontSize={"font_size_extraSmall"}
                      fontWeight={"rubik_regular"}
                      textColor={"text_color_primary"}
                      textAlign={"text_align_left"}
                    />
                  </span>
                  <span>
                    <CustomText
                      p
                      text={
                        data?.influencer_data.profession
                          ? `,${Utils.getSelectedSubCategoryObjects(
                              data?.influencer_data.profession
                            )}`
                          : ""
                      }
                      fontSize={"font_size_extraSmall"}
                      fontWeight={"rubik_regular"}
                      textColor={"text_color_primary"}
                      textAlign={"text_align_left"}
                    />
                  </span>
                  <span>
                    <CustomText
                      p
                      text={
                        data?.influencer_data.language
                          ? `,${data?.influencer_data.language}`
                          : ""
                      }
                      fontSize={"font_size_extraSmall"}
                      fontWeight={"rubik_regular"}
                      textColor={"text_color_primary"}
                      textAlign={"text_align_left"}
                    />
                  </span>
                </span>
                {data?.influencer_data.note && (
                  <div
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      lineHeight: "1.5",
                    }}
                  >
                    <CustomText
                      p
                      text={data?.influencer_data.note}
                      fontSize={"font_size_extraSmall"}
                      fontWeight={"rubik_regular"}
                      textColor={"text_color_primary"}
                      textAlign={"text_align_left"}
                    />
                  </div>
                )}
              </div>
            </div>
          </span>
        </>
      ),
    },
    {
      name: "Campaign Id",
      selector: (row) => (
        <span onClick={() => CampaignClick(row.invite_data.campaign_id)}>
          {row.invite_data.campaign_id}
        </span>
      ),
      textAlign: "center",
    },
    {
      name: "Request Date",
      selector: (row) => Utils.formatDate(row.invite_data.date_added),
      textAlign: "center",
    },
    {
      name: "",
      cell: (data) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={{ marginBottom: "8px" }}>
            <CustomButton
              text={"Send Price"}
              onClick={() => {
                setOpenSendPriceModal(!openSendPriceModal);
                setCampaignId(data.invite_data.campaign_id);
                setInfluencerId(data.influencer_data.influencer_id);
              }}
              fontSize={"font_size_small"}
              borderRadius={4}
              padding={{ top: 12, right: 12, bottom: 12, left: 12 }}
              textColor={"text_color_light"}
              background={"#090A14"}
              border={"none"}
              boxShadow={"none"}
              hoverColor={"var(--hover-color, #1977F2)"}
            />
          </span>
        </div>
      ),
      maxwidth: "100px",
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  function sendPriceUpdateProgress(status) {
    setSendPriceLoading(status);
  }
  function sendPrice() {
    let params = {
      campaign_id: campaignId,
      influencer_id: influencerId,
      bid_data: JSON.stringify({
        type: "cash",
        value: price,
        proposal_note: "abc",
      }),
    };
    console.log("params000", params);
    Core.sendPrice(
      sendPriceSuccess,
      sendPriceFailed,
      sendPriceUpdateProgress,
      params
    );
  }
  function sendPriceSuccess() {
    toast.success("Price sent");
    setOpenSendPriceModal(false);
    sendPriceLoading(false);
  }
  function sendPriceFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function ExpandedComponentData(item) {
    return (
      <ExpandedComponent
        item={item}
        influencerId={influencerId}
        campaignId={campaignId}
      />
    );
  }
  return (
    <div className="dashboard-container">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <>
              <div className="campaignlist-header">
                <div>
                  <CustomText
                    p
                    text={"Price Request"}
                    fontWeight={600}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                  />
                </div>
              </div>
              {priceRequestList?.length !== 0 ? (
                <>
                  <div className="custom-table">
                    <CustomTable
                      className="CustomTable"
                      tableSchema={tableSchema}
                      tableData={priceRequestList}
                      paginationPerPage={15}
                      rowPaddingTop={20}
                      rowPaddingBottom={30}
                      customStyles={customStyles}
                      expandableRows={true}
                      expandableRowsComponent={({ data }) => (
                        <ExpandedComponent
                          item={data}
                        //   influencerId={influencerId}
                        //   campaignId={campaignId}
                        />
                      )}
                      pagination
                    />
                  </div>
                </>
              ) : (
                <div className="fallback">
                  <FallBack heading={"No Price List"} />
                </div>
              )}
            </>
          )}
          {influencerFlyOut && (
            <InfluencerDetailModal
              influencerFlyOut={influencerFlyOut}
              toggleFlyoutDropdown={toggleFlyoutDropdown}
              influencer_id={influencerId}
            />
          )}
          {
            <ModalComponent
              isOpen={openSendPriceModal}
              onRequestClose={() => setOpenSendPriceModal(!openSendPriceModal)}
              customStyle={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999999,
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  backgroundColor: "#F0F3F9",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                  overflowY: "none",
                  height: "40%",
                  width: "30%",
                },
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="">
                  <CustomText p text={`Send Price`} textColor={"#040408"} />
                </div>
                <div
                  onClick={() => setOpenSendPriceModal(!openSendPriceModal)}
                  className="close-icon-brand-list"
                >
                  <Icon iconName="CloseRoundButtonIcon" />
                </div>
              </div>
              <div
                style={{
                  display: "flex ",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="m-t-20" style={{ width: "100%" }}>
                  <div className="">
                    <CustomText
                      p
                      text={`Price`}
                      textColor={"#040408"}
                      className="m-b-12"
                    />
                  </div>
                  <div className="">
                    <CustomTextInput
                      type="name"
                      placeholder="Price"
                      //   value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </div>
                <div style={{ marginTop: 20 }}>
                  <PrimaryCta
                    third
                    fontSize={"font_size_regular"}
                    text={"Send"}
                    onClick={() => {
                      sendPrice();
                    }}
                    isLoading={sendPriceLoading}
                  />
                </div>
              </div>
            </ModalComponent>
          }
        </div>
      </div>
    </div>
  );
};
export default PriceRequestList;
