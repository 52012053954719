import React, { useState, useEffect } from "react";
import { CustomText, PrimaryCta } from "../../components";
import Core from "../../common/clientSdk";
import { toast } from "react-toastify";
import { Loader } from "../loader";

const ExpandedComponent = (props) => {
  const [counterOfferLoading, setCounterOfferLoading] = useState(false);
  const [rejectOfferLoading, setRejectOfferLoading] = useState(false);
  const [bidDetailsLoading, setBidDetailsLoading] = useState(true);
  const [bidId, setBidId] = useState(null);
  const [bidValue, setBidValue] = useState(0);
  const [isCounter, setCounter] = useState(0);
  const [afterCounter, setAfterCounter] = useState(false);
  const [afterReject, setAfterReject] = useState(false);

  useEffect(() => {
    if (
      props?.item?.invite_data?.campaign_id &&
      props?.item?.influencer_data?.influencer_id
    ) {
      setBidDetailsLoading(true);
      bidData();
    }
  }, [props.item]);

  function bidData() {
    let params = {
      campaign_id: props?.item?.invite_data?.campaign_id,
      influencer_id: props?.item?.influencer_data?.influencer_id,
    };
    Core.bidDetails(bidDataSuccess, bidDataFailed, setBidDetailsLoading, params);
  }

  function bidDataSuccess(res, response) {
    setBidValue(response?.bid_deep_data?.bid_data?.counter_value || 0)
    setBidId(response?.bid_deep_data?.bid_data?.bid_id || null);
    setCounter(response?.bid_deep_data?.bid_data?.is_counter || 0);
    setBidDetailsLoading(false);
  }

  function bidDataFailed(errorList) {
    toast.error("Failed to fetch bid details.");
    setBidDetailsLoading(false);
  }

  function counterOffer() {
    let params = { bid_id: bidId };
    setCounterOfferLoading(true);
    Core.acceptOffer(
      () => {
        toast.success("Offer Accepted");
        setCounterOfferLoading(false);
        setAfterCounter(true); 
      },
      (errorList) => {
        toast.error(errorList.map((item) => item.m).join(", "));
        setCounterOfferLoading(false);
      },
      setCounterOfferLoading,
      params
    );
  }

  function rejectOffer() {
    let params = { bid_id: bidId };
    setRejectOfferLoading(true);
    Core.rejectOffer(
      () => {
        toast.success("Offer Rejected");
        setRejectOfferLoading(false);
        setAfterReject(true);
        setCounter(2); 
      },
      (errorList) => {
        toast.error(errorList.map((item) => item.m).join(", "));
        setRejectOfferLoading(false);
      },
      setRejectOfferLoading,
      params
    );
  }

  return (
    <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
  {bidDetailsLoading ? (
    <div className="bid-counter-card">
      <Loader />
    </div>
  ) : afterCounter ? (
    <div className="bid-counter-card">Counter offer accepted</div>
  ) : isCounter === 2 ? (
    <div className="bid-counter-card">Counter offer rejected</div>
  ) : isCounter === 1 && bidValue > 0 ? (
    <div className="bid-counter-card">
      <div>
        <CustomText
          p
          text={"Counter offer from Brand"}
          className="text-color-p text-12"
        />
      </div>
      <div>
        <CustomText p text={`Rs ${bidValue}`} className="text-14 m-t-6" />
      </div>

      <div className="counter-offer-buttons">
        <div>
          <PrimaryCta
            fifth
            className={"bid-btn-counter m-t-8"}
            text={"Accept"}
            disabled={afterCounter}
            isLoading={counterOfferLoading}
            onClick={counterOffer}
          />
        </div>
        <div>
          <PrimaryCta
            invert
            className={"bid-btn-counter m-t-8"}
            text={"Reject"}
            onClick={rejectOffer}
            disabled={afterReject}
            isLoading={rejectOfferLoading}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="bid-counter-card">There is no counter offer.</div>
  )}
</div>

  );
};

export default ExpandedComponent;
